import { Routes } from '@angular/router';
import { StartPageComponent } from '@pages/start-page/start-page.component';
import { OnboardingPageComponent } from '@pages/onboarding-page/onboarding-page.component';
import { MainPageComponent } from '@pages/main-page/main-page.component';
import { FaqPageComponent } from '@pages/faq-page/faq-page.component';
import { AwardsPageComponent } from '@pages/awards-page/awards-page.component';
import { CollectionPageComponent } from '@pages/collection-page/collection-page.component';
import { GamePageComponent } from '@pages/game-page/game-page.component';
import { ErrorPageComponent } from '@pages/error-page/error-page.component';
import { PreReleasePageComponent } from '@pages/pre-release-page/pre-release-page.component';
import { CorePageComponent } from '@pages/core-page/core-page.component';
import { RouteData } from '@interfaces/route-data.interface';
import { onboardingResolver } from '@src/app/resolvers/onboarding.resolver';
import { userResolver } from '@src/app/resolvers/user.resolver';
import { faqResolver } from '@src/app/resolvers/faq.resolver';
import { authGuard } from '@src/app/guards/auth.guard';
import { startGameResolver } from '@src/app/resolvers/start-game.resolver';
import { awardsResolver } from '@src/app/resolvers/awards.resolver';
import { onboardingGuard } from '@src/app/guards/onboarding.guard';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		component: StartPageComponent,
		data: { showHeader: false } as RouteData,
	},
	{
		path: 'onboarding',
		component: OnboardingPageComponent,
		data: { animation: 'onboarding', showHeader: false } as RouteData,
		canActivate: [authGuard, onboardingGuard],
		resolve: {
			slides: onboardingResolver,
		},
	},
	{
		path: 'core',
		component: CorePageComponent,
		data: { animation: 'core', showHeader: true } as RouteData,
		canActivate: [authGuard],
		children: [
			{
				path: '',
				pathMatch: 'prefix',
				redirectTo: 'main',
			},
			{
				path: 'game',
				component: GamePageComponent,
				data: {
					animation: 'game',
					showHeader: true,
					chipType: 'timer',
				} as RouteData,
				// resolve: {
				// 	startGame: startGameResolver,
				// },
			},
			{
				path: 'main',
				component: MainPageComponent,
				data: { animation: 'main', showHeader: true } as RouteData,
				// canActivate: [authGuard],
				resolve: {
					user: userResolver,
				},
			},
			{
				path: 'faq',
				component: FaqPageComponent,
				data: { animation: 'faq', showHeader: true } as RouteData,
				resolve: { items: faqResolver },
			},
			{
				path: 'awards',
				component: AwardsPageComponent,
				data: { animation: 'awards', showHeader: true } as RouteData,
				resolve: { awards: awardsResolver },
			},
			{
				path: 'collection',
				component: CollectionPageComponent,
				data: { animation: 'collection', showHeader: true } as RouteData,
			},
		],
	},
	{
		path: 'pre-release',
		component: PreReleasePageComponent,
		data: { showHeader: false },
	},
	{
		path: 'error',
		component: ErrorPageComponent,
		data: { showHeader: false },
	},
];
