import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { LogoWrapperComponent } from '@components/logo-wrapper/logo-wrapper.component';
import { StateService } from '@services/state.service';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { CrackerAnimationComponent } from '@components/cracker-animation/cracker-animation.component';

@Component({
	selector: 'app-main-page',
	standalone: true,
	imports: [RouterLink, LogoWrapperComponent, CrackerAnimationComponent],
	templateUrl: './main-page.component.html',
	styleUrl: './main-page.component.scss',
})
export class MainPageComponent {
	private state = inject(StateService);
	private router = inject(Router);
	private actRouter = inject(ActivatedRoute);
	private modal = inject(ModalService);
	private cdr = inject(ChangeDetectorRef);
	public user = this.state.user;
	public btnPressed: boolean;
	public isAfterRelease: boolean;

	constructor() {
		this.isAfterRelease = this.setIsAfterRelease();
	}

	public toGame(): void {
		if (this.btnPressed) return;
		this.btnPressed = true;

		if (this.state.user.attempts <= 0) {
			this.modal.openModal(ModalType.ATTEMPTS);
			this.resetButtonState(); // Сбрасываем состояние кнопки после завершения навигации
		} else {
			this.router
				.navigate(['../game'], { relativeTo: this.actRouter })
				.then(() => {
					this.resetButtonState(); // Сбрасываем состояние кнопки после завершения навигации
				});
		}
	}

	private setIsAfterRelease(): boolean {
		const targetDate = new Date('2024-09-30T23:30:00+03:00'); // Дата и время 11:00 по Москве
		const currentDateUTC = new Date(); // Текущее время в UTC

		// Вычисляем разницу между текущей датой и целевым временем
		const timeDifference = targetDate.getTime() - currentDateUTC.getTime();

		// Если текущее время меньше 11:00 10 сентября 2024, перенаправляем на "pre-release" страницу
		if (timeDifference > 0) {
			return false;
		}

		// Если время больше или равно 11:00 10 сентября 2024, разрешаем доступ
		return true;
	}

	private resetButtonState(): void {
		setTimeout(() => {
			this.btnPressed = false; // Разблокируем кнопку
		}, 500); // Тайм-аут можно настроить в зависимости от ваших нужд
	}
}
