import { ElementRef, inject, Injectable } from '@angular/core';
import { AnimatedSprite, Application, Assets, Sprite, Texture } from 'pixi.js';
import { TimerService } from '@services/timer.service';
import { ResourceLoaderService } from '@services/resource-loader.service';
import { PlayItem } from '@interfaces/play-item.interface';
import {
	BehaviorSubject,
	distinctUntilChanged,
	firstValueFrom,
	Observable,
	Subject,
	takeUntil,
} from 'rxjs';
import gsap from 'gsap';
import { PlayerDirections } from '@type/player-directions.type';
import { RestTime } from '@interfaces/rest-time.interface';
import { StartGame } from '@interfaces/start-game.interface';
import { ApiService } from '@services/api.service';
import { StopGame } from '@interfaces/stop-game.interface';
import { StopReason } from '@enums/stop-reason.enum';
import { ModalService } from '@modal/service/modal.service';
import { StopGameResponse } from '@interfaces/stop-game-response.interface';
import { ModalType } from '@modal/ts/modal-type.enum';
import { StatisticAction } from '@enums/statistic-action.enum';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class GameService {
	private timerService: TimerService = inject(TimerService);
	private resourcesService: ResourceLoaderService = inject(
		ResourceLoaderService
	);
	private api: ApiService = inject(ApiService);
	private levelCounter: number = 0;
	private router: Router = inject(Router);
	private modal: ModalService = inject(ModalService);
	private destroySubscriptions$ = new Subject<void>();
	private _app: Application;
	private _container: ElementRef;
	private _levelData: StartGame;
	private playerSprite: Sprite;
	private textures: Record<string, Texture>;
	private stripeWidth: number;
	private currentLaneIndex$ = new BehaviorSubject<number>(2);
	private disableMovement$ = new Subject<PlayerDirections>();
	private restLives$ = new BehaviorSubject<number>(3);
	private isPaused: boolean = false; // Флаг для отслеживания состояния паузы
	private _levelID$ = new BehaviorSubject<number>(1);
	private video: HTMLImageElement;
	private playerSpriteType$ = new BehaviorSubject<number>(1);
	private playItems: PlayItem[] = [];
	private expressPointsCounter$ = new BehaviorSubject<number>(0);
	private isMoveAnimation = false;
	private moveQueue: any[] = [];
	private levels: StartGame[] = [
		{
			level: 1,
			timeGame: 50,
			products: [11, 3, 4, 6, 7, 8],
			isPurchase: true,
			uid: '',
		},
		{
			level: 2,
			timeGame: 100,
			products: [12, 13, 14, 16, 17, 18],
			isPurchase: true,
			uid: '',
		},
		{
			level: 3,
			timeGame: 120,
			products: [22, 23, 24, 26, 27, 28],
			isPurchase: true,
			uid: '',
		},
	];
	public stopGameResponse: StopGameResponse;

	public set levelData(data: StartGame) {
		// this.levelID = data.level;
		this._levelData = data;
	}

	public get levelData(): StartGame {
		return this._levelData;
	}

	public set videoPlayer(video: HTMLImageElement) {
		this.video = video;
	}

	public get videoPlayer(): HTMLImageElement {
		return this.video;
	}

	public set levelID(id: number) {
		this._levelID$.next(id);
	}

	public get timer$(): Observable<RestTime> {
		return this.timerService.timer$;
	}

	public get lives$(): Observable<number> {
		return this.restLives$.asObservable();
	}

	public set lives(lives: number) {
		this.restLives$.next(lives);
	}

	public get disableControl$(): Observable<PlayerDirections> {
		return this.disableMovement$.asObservable();
	}

	public set container(el: ElementRef) {
		this._container = el;
	}

	public get container(): HTMLElement {
		return this._container.nativeElement;
	}

	public set app(app: Application) {
		this._app = app;
	}

	public get app(): Application {
		return this._app;
	}

	public offlineGame() {
		if (this.levelCounter < 3) {
			this.levelID = this.levelCounter;
			this.levelData = this.levels[this.levelCounter];
			this.levelCounter++;
		} else {
			this.levelCounter = 0;
			this.offlineGame();
		}
	}

	public async prepareGame(container: ElementRef) {
		this.offlineGame();
		this.container = container;
		this.app = new Application();
		await this.app.init({
			width: this.container.offsetWidth,
			height: this.container.offsetHeight,
			backgroundAlpha: 0,
		});
		this.app.stage.sortableChildren = true;
		this.container.appendChild(this.app.canvas);
		this.stripeWidth = this.app.renderer.width / 5;
		console.log(this.levelData);
		this.textures = await this.resourcesService.loadResources(
			this.levelData.products,
			this.levelData.level
		);
		this.initGame();
	}

	private initGame(): void {
		this.lives = 3;
		this.currentLaneIndex$.next(2);
		this.expressPointsCounter$.next(0);
		this.playItems = this.generateMarkers();
		gsap.globalTimeline.resume();
		this.initActors();
	}

	private initSubscriptions() {
		this.timerService.start(this.levelData.timeGame * 1000);
		this.initTimerSubscription();
		this.initLivesSubscription();
		this.initSpriteTypeSubscription();
	}

	private initSpriteTypeSubscription() {
		this.playerSpriteType$
			.asObservable()
			.pipe(takeUntil(this.destroySubscriptions$), distinctUntilChanged())
			.subscribe(type => this.updatePlayerSprite(type));
	}

	private initTimerSubscription() {
		this.timerService.timer$
			.pipe(takeUntil(this.destroySubscriptions$))
			.subscribe(({ expiration }) => {
				this.calculatePlayerSpriteType(expiration);
				if (expiration <= 0) {
					this.finish();
				} else {
					const elapsedTime = this.levelData.timeGame * 1000 - expiration;
					if (elapsedTime >= this.playItems[0]?.time) {
						const item = this.playItems.shift();
						this.initLabelItem(item);
					}
				}
			});
	}

	private calculatePlayerSpriteType(expiration: number): void {
		const totalTime = this.levelData.timeGame * 1000; // Общее время уровня
		const elapsedTime = totalTime - expiration; // Прошедшее время с начала уровня
		// Рассчитываем процент времени, которое прошло
		const progressPercentage = (elapsedTime / totalTime) * 100;

		// Определение типа спрайта в зависимости от прогресса времени
		if (elapsedTime <= 10000) {
			this.playerSpriteType$.next(1);
		} else if (elapsedTime > 10000 && progressPercentage <= 25) {
			this.playerSpriteType$.next(2);
		} else if (progressPercentage > 25 && progressPercentage <= 50) {
			this.playerSpriteType$.next(3);
		} else if (progressPercentage > 50 && progressPercentage <= 75) {
			this.playerSpriteType$.next(4);
		} else {
			this.playerSpriteType$.next(5);
		}
	}

	private initLivesSubscription() {
		this.lives$.pipe(takeUntil(this.destroySubscriptions$)).subscribe(lives => {
			if (lives <= 0) {
				this.failed();
			}
		});
	}

	public initActors(): void {
		// this.initBackground();
		this.initPlayer();
	}

	public startGame(): void {
		// this.video.play();
		this.initSubscriptions();
	}

	private initPlayer(): void {
		const texture = this.textures['player_grade_1']; // Замените на реальный путь к изображению
		// Создаем спрайт игрока
		this.playerSprite = new Sprite(texture);
		const startY = 0;
		// Устанавливаем начальные параметры спрайта игрока
		this.playerSprite.anchor.x = 0.5; // Центрирование по центру
		this.playerSprite.anchor.y = 0.5; // Центрирование по центру
		this.playerSprite.x = this.app.renderer.width / 2; // Позиционируем в центре по горизонтали
		this.playerSprite.y =
			this.app.renderer.height - this.playerSprite.height * 0.1; // Позиционируем чуть выше низа экрана
		this.playerSprite.alpha = 1; // Начальная прозрачность
		this.playerSprite.scale.set(0.3); // Начинаем с нулевого размера
		// this.calculateSizeSprite(this.playerSprite);

		// Добавляем спрайт игрока на сцену
		this.app.stage.addChild(this.playerSprite);

		this.startWobbleAnimation();
	}

	private startWobbleAnimation(): void {
		gsap.to(this.playerSprite, {
			x: '+=2', // Колебание влево-вправо
			duration: 0.3,
			ease: 'power1.inOut',
			yoyo: true,
			repeat: -1, // Бесконечная анимация
		});

		gsap.to(this.playerSprite, {
			y: '+=1', // Небольшое вертикальное колебание
			duration: 0.2,
			ease: 'power1.inOut',
			yoyo: true,
			repeat: -1, // Бесконечная анимация
		});
	}

	private initLabelItem(item: PlayItem): void {
		// Определяем начальную позицию
		const startX = this.app.renderer.width / 2; // Центр экрана// const startY = this.videoSprite.height - this.videoSprite.height * 0.68; // Начальная позиция по Y

		// Высота изображения, учитывая смещение вниз
		const imageHeight = this.videoPlayer.height;
		const offsetFromBottom = 160;
		const startY =
			this.app.renderer.height - offsetFromBottom - imageHeight * 0.35; // Начальная позиция по Y
		// Создаем спрайт
		const texture = this.textures['percentage-label'];
		const playItem = new Sprite(texture);

		playItem.anchor.set(0.5);
		playItem.x = startX;
		playItem.y = startY;
		playItem.alpha = 0; // Прозрачный в начале
		playItem.scale.set(0); // Начинаем с нулевого размера

		// Добавляем на сцену
		this.app.stage.addChild(playItem);

		// Определяем конечную позицию
		const stripeIndex = item.stripe - 1; // Приведение индекса полосы к значению от 0 до 4
		let stripeWidth: number;
		if (stripeIndex === 0 || stripeIndex === 4) {
			stripeWidth = this.stripeWidth * 0.6;
		} else {
			stripeWidth = this.stripeWidth;
		}
		let targetX: number;
		if (stripeIndex < 4) {
			targetX = stripeWidth * stripeIndex + stripeWidth / 2;
		} else {
			targetX = this.stripeWidth * stripeIndex + stripeWidth / 1.2;
		}
		const targetY = this.app.renderer.height;

		// Определяем участки пути с учетом начальной позиции
		const segmentLength = (targetY - startY) / 8; // Длина каждого из пяти сегментов
		const firstSegmentEnd = startY + segmentLength; // Конец первого участка
		const secondSegmentEnd = firstSegmentEnd + segmentLength; // Конец второго участка
		const thirdSegmentEnd = secondSegmentEnd + segmentLength; // Конец третьего участка
		let callbackCalled = false;

		// Анимация движения и изменения прозрачности
		gsap.to(playItem, {
			y: targetY, // Движение до нижней границы
			x: targetX,
			alpha: 0,
			duration: 13, // Полное время анимации
			onUpdate: () => {
				const currentY = playItem.y;

				// Управление увеличением размера
				if (currentY <= firstSegmentEnd) {
					playItem.scale.set(0); // Нулевой размер на первом сегменте// Увеличение размера от 0 до 0.5 в течение второго сегмента
				} else if (currentY <= thirdSegmentEnd) {
					const scaleProgress =
						0.5 +
						((currentY - secondSegmentEnd) /
							(thirdSegmentEnd - secondSegmentEnd)) *
							0.5;
					playItem.scale.set(scaleProgress < 1 ? scaleProgress : 1); // Увеличение размера от 0.5 до 1 в течение третьего сегмента
				} else {
					playItem.scale.set(1); // Размер остается 1 после третьего сегмента
				}
				// Управление увеличением размера и прозрачностью
				if (currentY <= firstSegmentEnd) {
					playItem.alpha = 0; // Полностью прозрачный
				} else if (currentY <= secondSegmentEnd) {
					const progress =
						(currentY - firstSegmentEnd) / (secondSegmentEnd - firstSegmentEnd);
					playItem.alpha = progress; // Появление на втором сегменте
				} else if (currentY <= thirdSegmentEnd) {
					const progress =
						1 -
						(currentY - secondSegmentEnd) /
							(thirdSegmentEnd - secondSegmentEnd);
					playItem.alpha = progress; // Исчезновение на третьем сегменте
				} else {
					playItem.alpha = 0; // Невидимый на четвертом и пятом сегментах

					// Вызываем коллбэк, когда элемент зашел на четвертый сегмент
					if (!callbackCalled) {
						callbackCalled = true;
						const gameItems = this.levelData.products;
						const randomIndex = Math.floor(Math.random() * gameItems.length);
						const alias = 'id' + gameItems[randomIndex];
						this.initItem(item, alias); // Вызываем следующую анимацию или выполняем другую логику
					}
				}
			},
		});
	}

	private initItem(item: PlayItem, alias: string = 'test-item'): void {
		const startXPoint = this.stripeWidth * item.stripe - this.stripeWidth / 2;
		const texture = this.textures[alias]; // Замените на реальный путь к изображению
		// Создаем спрайт игрока
		const playItem = new Sprite(texture);

		// Устанавливаем начальные параметры спрайта игрока
		this.calculateSizeSprite(playItem, this.stripeWidth);
		playItem.anchor.x = 0.5; // Центрирование по центру
		playItem.anchor.y = 0.5; // Центрирование по центру
		playItem.x = startXPoint; // Позиционируем в центре по горизонтали
		playItem.y = -playItem.height / 2; // Позиционируем чуть выше низа экрана
		playItem.alpha = 1; // Начальная прозрачность
		playItem.scale.set(0.05); // Размер остается 1 после 70% пути
		playItem.zIndex = 10;
		// Добавляем спрайт игрока на сцену
		this.app.stage.addChild(playItem);
		const checkCollision = () => {
			// Определяем полосу, в которой находится игрок
			const playerStripe =
				Math.floor(this.playerSprite.x / this.stripeWidth) + 1;

			// Проверяем, находятся ли элемент и игрок в одной полосе
			if (playerStripe === item.stripe) {
				const playerBounds = this.playerSprite.getBounds();
				const itemBounds = playItem.getBounds();
				// Смещаем нижнюю границу спрайта игрока вниз на заданное количество пикселей
				const collisionOffset = 10; // Например, смещение на 10 пикселей вниз
				const adjustedPlayerBounds = {
					x: playerBounds.x,
					y: playerBounds.y + collisionOffset,
					width: playerBounds.width,
					height: playerBounds.height - collisionOffset,
				};

				// Проверяем коллизию (Bounding Box Collision)
				return (
					adjustedPlayerBounds.x < itemBounds.x + itemBounds.width &&
					adjustedPlayerBounds.x + adjustedPlayerBounds.width > itemBounds.x &&
					adjustedPlayerBounds.y < itemBounds.y + itemBounds.height &&
					adjustedPlayerBounds.y + adjustedPlayerBounds.height > itemBounds.y
				);
			}

			return false;
		};
		const rotationDirection = Math.random() < 0.5 ? 1 : -1;

		gsap.to(playItem, {
			y: this.app.renderer.height + 150, // Движение вниз до нижней границы экрана
			rotation: rotationDirection * ((45 * Math.PI) / 180), // Вращение на 30 градусов (переводим в радианы)
			duration: 3.5, // Время анимации
			ease: 'power1.inOut', // Плавная анимация
			onUpdate: () => {
				// Рассчитываем, до какого значения должен увеличиваться scale до середины экрана
				const halfScreenY = this.app.renderer.height / 2;

				// Прогресс увеличения от 0.05 до 0.15 до середины экрана
				let scaleProgress;
				if (playItem.y <= halfScreenY) {
					const progress = playItem.y / halfScreenY; // Прогресс относительно середины экрана
					scaleProgress = 0.05 + progress * (0.15 - 0.05); // Увеличение от 0.05 до 0.15
				} else {
					scaleProgress = 0.15; // После середины экрана размер фиксируется на 0.15
				}

				playItem.scale.set(scaleProgress);

				if (checkCollision()) {
					gsap.killTweensOf(playItem); // Останавливаем анимацию
					// Создаем новый спрайт "item-caught" при столкновении
					const caughtTexture = this.textures['item-caught']; // Замена на реальную текстуру
					const caughtItem = new Sprite(caughtTexture);

					// Устанавливаем параметры "item-caught"
					caughtItem.anchor.set(0.5);
					caughtItem.x = playItem.x;
					caughtItem.y = playItem.y;
					caughtItem.alpha = 1;
					caughtItem.scale.set(0.35);
					this.app.stage.addChild(caughtItem);

					// Анимация "item-caught": немного поднимается вверх и исчезает
					gsap.to(caughtItem, {
						y: caughtItem.y - 150, // Подъем вверх
						alpha: 0, // Исчезновение через opacity
						duration: 3, // Время анимации
						ease: 'power1.out',
						onComplete: () => {
							if (
								this.app?.stage &&
								this.app.stage.children.includes(caughtItem)
							) {
								this.app.stage.removeChild(caughtItem); // Удаляем "item-caught" после завершения анимации
							}
						},
					});
					// Выполняем другие действия при столкновении
					gsap.to(playItem, {
						alpha: 0,
						duration: 0.3, // 300 мс
						onComplete: () => {
							if (
								this.app?.stage &&
								this.app.stage.children.includes(playItem)
							) {
								this.app.stage.removeChild(playItem); // Удаляем элемент после завершения анимации
							}
						},
					});
				}
			},
			onComplete: () => {
				if (this.app.stage.children.includes(playItem) && this.app?.stage) {
					if (this.restLives$.value > 0) {
						this.restLives$.next(this.restLives$.value - 1);
					}
					this.app.stage?.removeChild(playItem); // Удаляем элемент после завершения анимации
				}
			},
		});
	}

	private calculateSizeSprite(
		sprite: Sprite,
		targetWidth: number = this.stripeWidth + this.stripeWidth / 1.2
	): void {
		const originalWidth = sprite.texture.orig.width;
		const originalHeight = sprite.texture.orig.height;
		const aspectRatio = originalHeight / originalWidth;
		sprite.width = targetWidth;
		sprite.height = targetWidth * aspectRatio;
	}

	private updatePlayerSprite(type: number): void {
		this.playerSprite.texture = this.textures['player_grade_' + type];

		// Принудительно обновляем рендер
		this.playerSprite.texture.update();
		// this.app.renderer.render(this.app.stage); // Обновляем сцену
	}

	private updatePlayerPosition(): void {
		const initialX = this.playerSprite.x; // Начальная позиция

		const targetX =
			this.stripeWidth * this.currentLaneIndex$.value + this.stripeWidth / 2;
		let halfwayPointReached = false;
		let targetRotation = 0; // По умолчанию угол 0 градусов
		switch (this.currentLaneIndex$.value) {
			case 0:
				targetRotation = -25; // Крайняя левая позиция
				break;
			case 1:
				targetRotation = -11.5; // Вторая слева позиция
				break;
			case 2:
				targetRotation = 0; // Центральная позиция
				break;
			case 3:
				targetRotation = 11.5; // Вторая справа позиция
				break;
			case 4:
				targetRotation = 25; // Крайняя правая позиция
				break;
		}
		gsap.killTweensOf(this.playerSprite, { x: true, y: true });

		gsap.to(this.playerSprite, {
			x: targetX,
			rotation: -targetRotation * (Math.PI / 180),
			duration: 0.1,
			ease: 'sine.inOut',
			inertia: {
				x: targetX,
				rotation: -targetRotation * (Math.PI / 180),
			},
			onComplete: () => {
				// Возобновляем колебание после перемещения
				this.isMoveAnimation = false;
				this.startWobbleAnimation();
			},
		});
	}

	public moveLeft(): void {
		// Если текущая очередь содержит противоположное направление, очищаем её
		if (
			this.moveQueue.length > 0 &&
			this.moveQueue[this.moveQueue.length - 1] === 'right'
		) {
			this.moveQueue = [];
		}

		if (this.isMoveAnimation) {
			this.moveQueue.push('left');

			return;
		}
		this.startMove('left');
	}

	public moveRight(): void {
		// Если текущая очередь содержит противоположное направление, очищаем её
		if (
			this.moveQueue.length > 0 &&
			this.moveQueue[this.moveQueue.length - 1] === 'left'
		) {
			this.moveQueue = [];
		}

		if (this.isMoveAnimation) {
			this.moveQueue.push('right');
			return;
		}
		this.startMove('right');
	}

	private startMove(direction: 'left' | 'right'): void {
		if (direction === 'left' && this.currentLaneIndex$.value > 0) {
			this.isMoveAnimation = true;
			this.disableMovement$.next(null);
			this.currentLaneIndex$.next(this.currentLaneIndex$.value - 1);
			this.updatePlayerPosition();
		} else if (direction === 'right' && this.currentLaneIndex$.value < 4) {
			this.isMoveAnimation = true;
			this.disableMovement$.next(null);
			this.currentLaneIndex$.next(this.currentLaneIndex$.value + 1);
			this.updatePlayerPosition();
		}

		if (this.currentLaneIndex$.value === 0) {
			this.disableMovement$.next('left');
		} else if (this.currentLaneIndex$.value === 4) {
			this.disableMovement$.next('right');
		}

		this.onMoveComplete();
	}

	private onMoveComplete(): void {
		// Эта функция вызывается, когда анимация движения завершена
		this.isMoveAnimation = false;
		console.log('onMoveComplete', this.moveQueue);
		// Проверяем, есть ли еще действия в очереди
		if (this.moveQueue.length > 0) {
			const nextMove = this.moveQueue.shift(); // Извлекаем следующий элемент из очереди
			if (nextMove) {
				this.startMove(nextMove);
			}
		}
	}

	// Метод для паузы игры
	public pause(): void {
		if (!this.isPaused) {
			this.isPaused = true;
			this.timerService.pause(); // Предполагается, что ваш таймер поддерживает паузу
			// this.videoPlayer.pause();
			gsap.globalTimeline.pause(); // Пауза всех анимаций GSAP
			// Остановка других процессов, если необходимо
		}
	}

	// Метод для продолжения игры
	public resume(): void {
		if (this.isPaused) {
			this.isPaused = false;
			this.timerService.resume(); // Предполагается, что ваш таймер поддерживает возобновление
			// this.videoPlayer.play();
			gsap.globalTimeline.resume(); // Возобновление всех анимаций GSAP
			// Возобновление других процессов, если необходимо
		}
	}

	public async stop(data: StopGame): Promise<boolean> {
		// 1. Остановить все анимации GSAP
		gsap.globalTimeline.clear(); // Полностью очищает глобальную временную шкалу GSAP, останавливая все анимации

		// 2. Остановить таймер
		this.timerService.pause(); // Предполагается, что ваш таймер поддерживает остановку (нужно реализовать метод stop в TimerService)

		// 3. Завершить все подписки
		this.destroySubscriptions$.next(); // Отправляем сигнал для завершения всех подписок
		this.destroySubscriptions$.complete();

		// 4. Очистить сцену от всех спрайтов
		this.app.stage?.removeChildren(); // Удаляет всех детей из stage

		// 5. Освободить ресурсы (опционально)
		this.app.destroy(true, { children: true, texture: true }); // Уничтожает приложение PixiJS и освобождает все ресурсы

		// 6. Другие действия для остановки игры
		// this.videoPlayer.pause();
		// firstValueFrom(
		// 	this.api.sendStatistic(
		// 		StatisticAction.LAST_COMPLETED_LEVEL_NUMBER,
		// 		this.levelData.level
		// 	)
		// );

		return this.router.navigate(['/core']);
	}

	public close() {
		const data: StopGame = {
			reason: StopReason.EXIT,
			uid: this.levelData.uid,
		};
		if (this.levelData.isPurchase) {
			data.express = 5;
		}
		// const action = this.levelData.isPurchase
		// 	? StatisticAction.AMOUNT_EXITS_BY_CROSS_PAYED
		// 	: StatisticAction.AMOUNT_EXITS_BY_CROSS_FREE;
		// firstValueFrom(this.api.sendStatistic(action, '1'));
		this.stop(data);
	}

	public finish() {
		const data: StopGame = {
			reason: StopReason.SUCCESS,
			uid: this.levelData.uid,
		};
		// if (this.levelData.isPurchase) {
		// 	data.express = 5;
		// 	firstValueFrom(
		// 		this.api.sendStatistic(StatisticAction.AMOUNT_COMPLETED_GAMES_PAYED, 1)
		// 	);
		// } else {
		// 	firstValueFrom(
		// 		this.api.sendStatistic(StatisticAction.AMOUNT_COMPLETED_GAMES_FREE, 1)
		// 	);
		// }

		this.stop(data);
		this.modal.openModal(ModalType.LEVEL_FINISHED, true, {
			data: {
				award: {
					isPremier: false,
					promocode: true,
				},
				is20LevelComplete: false,
				description: 'Вы прошли уровень!',
				express: 10,
				level: this.levelData.level,
				isPaidAttempt: this.levelData.isPurchase,
			},
		});
		// this.stop(data).then(res => {
		// 	this.stopGameResponse = res;
		// 	this.modal.openModal(ModalType.LEVEL_FINISHED, true, {
		// 		data: {
		// 			...res,
		// 			express: res.express,
		// 			level: this.levelData.level,
		// 			isPaidAttempt: this.levelData.isPurchase,
		// 		},
		// 	});
		// });
	}

	public failed() {
		this.modal.openModal(ModalType.LEVEL_FAILED);
		const data: StopGame = {
			reason: StopReason.LOOSE,
			uid: this.levelData.uid,
		};
		// if (this.levelData.isPurchase) {
		// 	data.express = 5;
		// 	firstValueFrom(
		// 		this.api.sendStatistic(
		// 			StatisticAction.AMOUNT_PAYED_GAMES_NOT_COMPLETED,
		// 			1
		// 		)
		// 	);
		// } else {
		// 	firstValueFrom(
		// 		this.api.sendStatistic(
		// 			StatisticAction.AMOUNT_FREE_GAMES_NOT_COMPLETED,
		// 			1
		// 		)
		// 	);
		// }

		this.stop(data);
	}

	private generateMarkers(): PlayItem[] {
		const markers: PlayItem[] = [];
		let i = 1;
		let T = 3 * 1000; // Первый маркер всегда появляется на 3-й секунде (в миллисекундах)

		// Генерация первого маркера
		let R = Math.floor(Math.random() * 5) + 1; // Выбор случайной дорожки от 1 до 5
		markers.push({ id: i, imgId: 1, stripe: R, time: T });

		let N = this.levelData.level;
		// Ограничение сложности для уровней выше 22-го
		if (N > 22) {
			N = 22;
		}

		// Генерация последующих маркеров
		i = 2;
		while (T < this.levelData.timeGame * 1000 - 10 * 1000) {
			// Сравниваем время в миллисекундах
			// Выбор случайной дорожки для нового маркера
			R = Math.floor(Math.random() * 5) + 1;

			// Расчет времени появления следующего маркера
			const previousMarker = markers[i - 2]; // Предыдущий маркер
			const minTimeGap = Math.abs(R - previousMarker.stripe) * 1000; // Минимальное время для перемещения между дорожками, 500 мс (50 в оригинале, но с учетом миллисекунд)
			const randomLuff =
				(((Math.floor(Math.random() * 61) - N * 2) % 31) + 5) * 100; // Случайный люфт времени (0-2000 мс)
			const nextMarkerTime = previousMarker.time + minTimeGap + randomLuff;
			const delay = previousMarker.stripe === R ? 1000 : 0;
			T = Math.max(nextMarkerTime, previousMarker.time + delay);

			// Добавляем новый маркер в список
			markers.push({ id: i, imgId: 1, stripe: R, time: T });
			i++;
		}
		return markers;
	}

	private generateExpressPoints(numberOfExpressPoints: number = 5): PlayItem[] {
		const expressPoints: PlayItem[] = [];
		const interval =
			(this.levelData.timeGame * 1000 - 10 * 1000) / numberOfExpressPoints; // Интервал времени между появлением каждого балла

		for (let i = 0; i < numberOfExpressPoints; i++) {
			const stripe = Math.floor(Math.random() * 5) + 1;
			const time = Math.floor(interval * i + interval / 2); // Появление балла в середине каждого интервала
			expressPoints.push({ id: i + 1, time, stripe, imgId: 0 });
		}

		return expressPoints;
	}
}
