<div class="main-page">
	<div class="play">
		<div class="play__inner">
			@if (isAfterRelease) {
				<p class="play__description final">Твои награды<br />ждут тебя!</p>
        <a class="play__btn" routerLink="../game">Играть</a>
			} @else {
				<p class="play__text">Уровень {{ user.nextLevel }}</p>
				<p class="play__description">{{ user.description }}</p>
				<a class="play__btn" (click)="toGame()">Играть</a>
			}
		</div>
	</div>
	<div class="menu">
		<a class="menu__btn" routerLink="../awards">
			<img class="logo__image" src="./assets/images/award-icon.svg" />
			<span>Награды</span>
		</a>
		<a class="menu__btn" routerLink="../collection">
			<img class="logo__image" src="./assets/images/collection-icon.svg" />
			<span>Коллекция</span>
		</a>
		<a class="menu__btn" routerLink="../faq">
			<img class="logo__image" src="./assets/images/questions-icon.svg" />
			<span>Вопросы</span>
		</a>
	</div>
	<app-logo-wrapper></app-logo-wrapper>
</div>
<app-cracker-animation class="cracker-animation"></app-cracker-animation>
