<div class="modal-level-complete">
	<!--  <button class="modal-level-complete__close close-btn" (click)="closeFinishModal()">-->
	<!--    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">-->
	<!--      <path d="M3.66355 1.0901C2.95291 0.379463 1.80074 0.379461 1.09011 1.0901C0.379471 1.80073 0.379469 2.9529 1.0901 3.66354L4.92656 7.50001L1.0901 11.3365C0.379469 12.0471 0.379471 13.1993 1.09011 13.9099C1.80074 14.6206 2.95291 14.6205 3.66355 13.9099L7.5 10.0734L11.3365 13.9099C12.0471 14.6205 13.1993 14.6206 13.9099 13.9099C14.6205 13.1993 14.6205 12.0471 13.9099 11.3365L10.0734 7.50001L13.9099 3.66354C14.6205 2.9529 14.6205 1.80073 13.9099 1.0901C13.1993 0.379461 12.0471 0.379463 11.3365 1.0901L7.5 4.92656L3.66355 1.0901Z" fill=""/>-->
	<!--    </svg>-->
	<!--  </button>-->
	<div class="modal-level-complete__inner">
		<img class="modal-level-complete__bird" src="./assets/images/bird.svg" />
		<img
			class="modal-level-complete__decoration"
			src="./assets/images/modal-decoration.png" />
    @if(data.level < 23){
      <div class="modal-level-complete__title"
        >Уровень {{ data.level }} пройден!</div
      >
    } @else {
      <div class="modal-level-complete__title"
        >Бонусная игра пройдена!</div
      >
    }
		<div class="modal-level-complete__subtitle"
			>Это было круто! Ты заработал:</div
		>

		@if (data?.express) {
			<div class="modal-level-complete__row">
				<img src="./assets/images/express-icon.svg" />
				<div class="modal-level-complete__col">
					<div class="modal-level-complete__col-title"
						>+ {{ data.express | expressPoints }}</div
					>
					<div class="modal-level-complete__col-text"
						>Используй в течение 7 дней</div
					>
				</div>
			</div>
		}

		@if (data.award.promoCode) {
			@if (data.award.isPremier) {
				<div class="modal-level-complete__row">
					<img src="./assets/images/icon-premier.svg" />
					<div class="modal-level-complete__col">
						<div class="modal-level-complete__col-title"
							>Промокод от PREMIER</div
						>
						<div class="modal-level-complete__col-text"
							>Ищи его в разделе наград</div
						>
					</div>
				</div>
			} @else {
				<div class="modal-level-complete__row">
					<img src="./assets/images/percent-icon.svg" />
					<div class="modal-level-complete__col">
						<div class="modal-level-complete__col-title">Промокод от АШАН</div>
						<div class="modal-level-complete__col-text"
							>Ищи его в разделе наград</div
						>
					</div>
				</div>
			}
		}
		<div class="modal-level-complete__text">{{ data.description }}</div>
		<button class="btn btn-primary" (click)="closeFinishModal()"
			>Забрать награду</button
		>
	</div>
	<app-cracker-animation class="cracker-animation"></app-cracker-animation>
</div>
