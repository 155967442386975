<div class="promo-code__slide">
	<div class="promo-code__inner">
		@if (slide.isPremier && slide.isAvailable) {
			<img class="promo-code__image" src="./assets/images/logo-premier.svg" />
		}
		<div class="promo-code__title">{{ slide.name }}</div>
		@if (slide.isPremier && !slide.isAvailable) {
			<img class="promo-code__image" src="./assets/images/logo-premier.svg" />
		}
		@if (!slide.isPremier && !slide.isAvailable) {
      <div class="promo-code__title _second">Играй каждый день <br> и проходи уровни!</div>
		}
		@if (slide.isAvailable) {
			<div class="promo-code__code">{{ slide.code }}</div>

			<button
				class="promo-code__btn btn btn-primary"
				[disabled]="isCopied"
				(click)="copyToClipboard(slide.code, slide.isPremier)">
				Скопировать
			</button>
		}

		@if (slide.isAvailable && slide.isPremier) {
			<a href="https://premier.one/profile/promocode" class="promo-code__btn btn btn-secondary" (click)="sendStatisticActivateBtn()"> Активировать </a>
		}
		@if (slide.isAvailable && !slide.isPremier) {
			<div class="promo-code__subtitle">Скидка не более 500 ₽</div>
			<div class="promo-code__date">
				<img src="./assets/images/calendar-icon.svg" />
				<span>{{ slide.expired | toDateString }}</span>
			</div>
			<div class="promo-code__text"
				>Примени его с 10 сентября по 07 октября 2024. Не распространяется на 
				алкогольную продукцию.</div
			>
		}
		@if (slide.isAvailable && slide.isPremier) {
			<div class="promo-code__text"
				>Активируй промокод с 10 сентября <br />
				по 15 октября включительно!</div
			>
		}
	</div>
	@if (slide.isAvailable && !slide.isPremier) {
		<a href="auchan://category?code=den_rozhdeniya_ashan_2022" class="promo-code__link" (click)="sendStatisticMoreBtn()">Узнать больше</a>
	}
</div>
