import {
	AfterViewChecked,
	AfterViewInit,
	Component,
	ElementRef,
	inject,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { GameContainerComponent } from '@components/game-container/game-container.component';
import { GameControlComponent } from '@components/game-control/game-control.component';
import { GameService } from '@services/game.service';
import { PlayerDirections } from '@type/player-directions.type';
import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@modal/service/modal.service';
import { ModalType } from '@modal/ts/modal-type.enum';
import { BehaviorSubject, firstValueFrom, map, Subject } from 'rxjs';
import { StatisticAction } from '@enums/statistic-action.enum';
import { ApiService } from '@services/api.service';

@Component({
	selector: 'app-game-page',
	standalone: true,
	imports: [
		GameContainerComponent,
		GameControlComponent,
		AsyncPipe,
		NgOptimizedImage,
	],
	templateUrl: './game-page.component.html',
	styleUrl: './game-page.component.scss',
})
export class GamePageComponent implements OnInit, AfterViewInit {
	private gameService = inject(GameService);
	private modalService = inject(ModalService);
	private activatedRoute = inject(ActivatedRoute);
	private api = inject(ApiService);
	public disableMovement$ = this.gameService.disableControl$;
	public restLives$ = this.gameService.lives$;
	public currentLevel$ = new BehaviorSubject<number>(1);
	public src: string = './assets/game-resources/webp-test.webp';

	@ViewChild('game', { static: true }) game: GameContainerComponent;
	@ViewChild('videoPlayer', { static: false })
	videoPlayer: ElementRef<HTMLImageElement>;

	constructor() {}

	async ngOnInit(): Promise<void> {
		this.modalService.openModal(ModalType.LOADER, false);
		// this.gameService.offlineGame();
		// this.gameService.levelData = {
		// 	level: 1,
		// 	timeGame: 180,
		// 	products: [1, 3, 4, 6, 7, 8],
		// 	isPurchase: true,
		// 	uid: '',
		// };
		// this.gameService.levelData = this.activatedRoute.snapshot.data['startGame'];
		await this.gameService.prepareGame(this.game.container);
		const levelID = this.gameService.levelData.level;
		this.currentLevel$.next(levelID);
		// const action = this.gameService.levelData.isPurchase
		// 	? StatisticAction.AMOUNT_PAYED_GAMES_ATTEMPTS
		// 	: StatisticAction.AMOUNT_FREE_GAMES_ATTEMPTS;
		// firstValueFrom(this.api.sendStatistic(action, '1'));
	}

	ngAfterViewInit(): void {
		this.gameService.videoPlayer = this.videoPlayer.nativeElement;
		// this.gameService.videoPlayer = this.videoPlayer.nativeElement;
	}

	videoLoaded() {
		console.log('videoLoaded', this.videoPlayer.nativeElement.height);
	}

	movePlayer(direction: PlayerDirections) {
		switch (direction) {
			case 'left':
				this.gameService.moveLeft();
				break;
			case 'right':
				this.gameService.moveRight();
				break;
			default:
				break;
		}
	}
}
